#nav-main {
    position: relative;
    z-index: 10;
    background: $color-main;

    .container--nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .menu__nav__list {
        display: flex;
        flex-wrap: wrap;
        transition: $duration ease-out;
        min-width: 1200px;
        margin-left: -15px;
    } 

    .nav-main-item {
        border-right: 1px solid $color-white;
        width: 300px;

        &:first-child {
            border-left: 1px solid $color-white;
        }

        &:hover {
            .nav-main-link {
                background: $color-bg--neutral;
                color: $color-text;

                &:before {
                    background-color: $color-bg--neutral;
                }
            }
        }

        &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
            .sub-menu {
                display: flex;
                justify-content: center;
            }
        }

        &:last-child {
           .nav-main-link {
                &:after {
                    width: 0;
                    height: 0;
                    background: transparent;
                }
            }
        }

        .nav-main-link {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            height: 110px;
            width: 300px;
            padding: 0 0 0 40px;
            color: $color-white;
            text-transform: uppercase;
            font-family: $font-family;
            font-weight: $font-weight-medium;
            font-size: $font-size--4;
            line-height: 26px;

            span {
                font-weight: $font-weight-bold;
            }

            &:before {
                position: absolute;
                content: " ";
                width: 10px;
                height: 10px;
                left: -5px;
                top: 50%;
                transform: translateY(-50%);
                background-color: $color-white;
                border-radius: 50%;
                transition: all $duration ease;
            }

            
        }

        .sub-menu {
            display: none;
            position: absolute;
            left: 0;
            width: 100%;
            padding: 30px 15px;
            background: $color-main;
            border-top: 1px solid $color-white;
            box-shadow: $shadow;

            ul {
                width: 100%;
                column-count: 3;
                column-gap: 30px;
            }

            .nav-child-item, .nav-grandchild-item {
                display: inline-flex;
                width: 100%;
                margin: -1px; // Fix space beetween inline-flex
                padding: 0;
                line-height: initial;

                a {
                    position: relative;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 60px;
                    padding: 15px 30px 15px 0;
                    margin-bottom: 1px; // Fix space beetween inline-flex
                    color: $color-white;
                    font-family: $font-family;
                    font-weight: $font-weight-bold;
                    font-size: $font-size--text;
                    line-height: 22px;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
                    z-index: 0;

                    &::before {
                        content: "";
                        display: inherit;
                        position: absolute;
                        bottom: 0;
                        width: 0;
                        height: 3px;
                        background: $color-white;
                        transition: all .5s;
                    }

                    &:after {
                        position: absolute;
                        content: "";
                        width: 100%;
                        height: 100%;
                        background-image: url(/wp-content/themes/cc-pays-de-lalbenque-limogne/assets/src/images/sprite-images/home/Rond-menu.svg);
                        top: 15px;
                        left: 0;
                        background-repeat: no-repeat;
                        opacity: 0;
                        z-index: -2;
                        transition: all $duration ease;
                    }

                    &:hover {
                        padding-left: 20px;

                        &::before {
                            width: 100%;
                            left: 0;
                        }

                        &:after {
                            opacity: 1;
                        }
                    }
                }
            }

            .nav-grandchild-item {
                a {
                    display: flex;
                    padding: 17px 10px 17px 15px;
                    border-bottom: 0;
                    font-size: $font-size--text-small;
                    font-weight: $font-weight;
                    text-transform: initial;
                    line-height: initial;

                    &::before {
                        display: none;
                    }
                } 
            }
        }
    }
}

// Responsive button label
.nav-main_icon {
    display: none;
}

// Responsive button
.nav-main_button {
    display: none;
}


//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    #nav-main {
        .nav-main-item {
            width: 240px;
            .nav-main-link {
                width: 240px;
                font-size: $font-size--5;
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    #nav-main {
        position: relative;

        .container--nav {
            position: relative;
            border-right: 1px solid white;
            border-left: 1px solid white;

            &:before {
                position: absolute;
                content: "";
                width: 1px;
                height: 100%;
                left: 50%;
                top: 0;
                background-color: $color-white;
            }

            &:after {
                position: absolute;
                content: "";
                width: 10px;
                height: 10px;
                top: 50%;
                left: -5px;
                transform: translateY(-50%);
                background-color: $color-white;
                border-radius: 50%;
            }
        }

        .menu__nav__list {
            display: flex;
            flex-flow: column;
            position: absolute;
            margin: auto 95px;
            width: 960px;
            max-width: 960px;
            min-width: 960px;
            top: 75px;
            right: -255px;
            background: $color-main;
            border-top: 1px solid $color-white;
        }

        .nav-main-item {
            width: unset;
            border: none;

            &:first-child {
                border: none;
            }

            .nav-main-link {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                max-width: 100%;
                height: 80px;
                padding: 0 20px;
                border-bottom: 1px solid $color-white;
                color: $color-white;
                text-transform: uppercase;

                &:first-child {
                    padding: 0 20px;
                }

                &:after, &:before {
                    display: none;
                }
            }

            &:last-child {
                .nav-main-link {
                    border-bottom: 0;

                    &:after {
                        width: 0;
                        height: 0;
                        background: $color-bg--transparent;
                    }
                }
            }

            &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
                .sub-menu {
                    display: none;
                }
            }

            &.active,
            &.menu-item-has-children.active {
                background: $color-bg--neutral;
                box-shadow: 0 0 1px rgb(27 35 54 / 8%), 1px 1px 10px rgb(27 35 54 / 8%);

                .nav-main-link {
                    color: $color-dark;
                }

                .sub-menu {
                    display: flex;
                    position: relative;
                    padding: 5px 10px;
                    box-shadow: none;
                    background-color: $color-white;
                    border-top: none;

                    .nav-drop {
                        column-count: 1;
                    }

                    .nav-grandchild-item {
                        a {
                            padding: 10px;
                        }
                    }

                    .nav-child-item {
                        a {
                            color: $color-dark;
                            border-bottom: 1px solid $color-dark;

                            &:before {
                                background-color: $color-dark;
                            }
                        }
                    }

                    li:last-child {
                        a {
                            border-bottom: 0;

                            &::before {
                                display: none;
                            }

                            &::after {
                                top: 50%;
                                transform: translate(0,-50%);
                            }
                        }
                    }
                }
            }

        }

        // Responsive button
        .nav-main_button {
            ~ .menu {
                clear: both;
                transform: scaleY(0);
                transform-origin: 50% 0;
                transition:transform .3s ease;
            }

            &:checked {
                ~ .menu {
                    transform: scaleY(1);
                }
                ~ .nav-main_icon {
                    .navicon {
                        background: $color-bg--transparent;

                        &:before {
                            transform: rotate(-45deg);
                        }

                        &:after {
                            transform: rotate(45deg);
                        }
                    }

                    &:not(.steps) {
                        .navicon {
                            &:before,
                            &:after {
                                top: 0;
                            }
                        }
                    }
                }
            }
        }

        // Responsive button label
        .nav-main_icon {
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 10px 20px;
            color: $color-white;
            position: relative;
            margin: 0;
            padding: 0;
            width: fit-content;
            height: 75px;
            font-family: $font-family;
            font-weight: $font-weight-medium;
            font-size: 18px;
            line-height: 20px;
            text-transform: uppercase; 
            margin-left: 15px;

            &:before {
                position: absolute;
                content: "";
                right: -10px;
                top: 17px;
                width: 40px;
                height: 40px;
                background-color: $color-dark;
                border-radius: 50px;
                transition: all $duration ease-out;
            }

            .navicon {
                position: relative;
                display: block;
                height: 2px;
                top: -1px;
                width: 20px;
                margin-left: 25px;
                background: $color-white;
                transition: all $duration ease-out;

                &:before, 
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: $color-white;
                    transition: all $duration ease-out;
                }

                &:before {
                    top: 6px;
                }

                &:after {
                    top: -6px;
                } 
            }

            &:hover {
                &:before {
                    background-color: $color-second;
                }

                .navicon {
                    background-color: $color-dark;

                    &:before, 
                    &:after {
                        background-color: $color-dark;
                    }
                }
            }
        }
    }
    
}


// 640
@media screen and (max-width: $small) {

    #nav-main {
        .container--nav {
            &:before {
                display: none;
            }
        }

        .nav-main_icon {
            left: 50%;
            transform: translateX(-50%);
            height: 80px;
            margin-left: 0;

            .navicon {
                top: -3px;
            }
        }

        .menu__nav__list {
            width: 640px;
            max-width: 640px;
            min-width: 640px;
            left: -256px;
        }
    }
}
