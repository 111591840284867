@font-face {
    font-family: 'Sofia Sans Bold';
    src: url("/wp-content/themes/cc-pays-de-lalbenque-limogne/assets/src/fonts/SofiaSans-Bold.woff2") format("woff2");
}

@font-face {
    font-family: 'Sofia Sans';
    src: url("/wp-content/themes/cc-pays-de-lalbenque-limogne/assets/src/fonts/SofiaSans-Regular.woff2") format("woff2");
}

.home {

    &__title {
        font-family: 'Sofia Sans Bold';
        font-weight: $font-weight-normal;
        font-size: 50px;
        line-height: 55px;
        color: $color-dark;
        text-transform: lowercase;
        border-bottom: 8px solid $color-dark;
        padding-bottom: 5px;
        width: fit-content;
        margin: 0;
        padding: 0;

        &:before {
            display: none;
        }
    }

    &__button {
        text-transform: lowercase;
        min-height: 40px;
        width: fit-content;
        padding: 0 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $font-family;
        font-weight: $font-weight-semibold;
        font-size: $font-size--text;
        background-color: $color-main;
        color: $color-white;
        border-radius: 50px;
        line-height: 20px;
        gap: 10px;
        border: none;
        position: relative;
        z-index: 2;
        min-width: 270px;

        span {
            width: 20px;
            height: 4px;
            background-color: $color-white;
            transition: all $duration ease;
            position: relative;
            margin-left: 0;

            &:before {
                position: absolute;
                content: "";
                width: 4px;
                height: 0;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: $color-white;
                transition: all $duration ease;
                z-index: -1;
            }
        }

        &:hover, &:focus {
            background-color: $color-dark;
            color: $color-white;

            span {
                background-color: $color-white;
                width: 16px;
                margin-left: 4px;

                &:before {
                    height: 15px;
                }
            }
        }
    }
}

//======================================================================================================
// SlideShow
//======================================================================================================
.home-slideshow {
    position: relative;
    margin-top: 30px;

    > .container {
        position: relative;
    }

    .container {
        &--content {
            position: relative;
            height: 100%;
        }

        &--navigation, &--pagination {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
        }
    }

    .slideshow {
        height: 540px;
        width: 1170px;
        position: relative;
        border-radius: 0 0 65px 0;

        &:before, &:after {
            position: absolute;
            content: "";
            width: 15px;
            height: 100%;
            top: 0;
            background-color: $color-white;
            z-index: 10;
        }

        &:before {
            left: 385px;
        }

        &:after {
            right: 385px;
        }

        img, video {
            position: absolute;
            object-fit: cover;
            object-position: top;
            width: 100%;
            height: 100%;
        }

        &__container {
            display: inherit;
            position: relative;
            width: 100%;
            height: 100%;
        }

        &__content {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 385px;
            height: auto;
            padding: 20px 0 15px;
            background: $color-white;
            color: $color-dark;
        }

        &__title {
            margin-bottom: 15px;
            font-family: $font-family;
            font-weight: $font-weight-medium;
            font-size: 25px;
            line-height: 22px;
        }

        &--video {
            img {
                display: none;
            }
        }

        // Pagination (bullet)
        &__pagination {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 2;
            width: auto;
    
            .swiper-pagination-bullet {
                width: 15px;
                height: 15px;
                margin: 0 10px 0 0;
                transition: .25s;
                background-color: $color-third;
                border-radius: 50px;
                opacity: 1;
    
                &:hover, &:focus {
                    background-color: $color-main;
                }
    
                &-active {
                    background-color: $color-second;
                }
            }
        }
    
    }
}

//======================================================================================================
// Accès rapides
//======================================================================================================

.access {
    margin-top: 80px;
    padding: 90px 0;
    background-color: $color-third;
    position: relative;

    &:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background-image: url(/wp-content/themes/cc-pays-de-lalbenque-limogne/assets/src/images/sprite-images/home/Motif.png);
        top: 0;
        left: 0;
        z-index: 0;
        background-repeat: repeat;
    }

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }

    &__title {
        transform: rotate(-90deg);
        height: fit-content;
        color: $color-white;
        border-color: $color-white;
        position: absolute;
        left: -70px;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: 70px 0;
        justify-content: space-between;
        margin-left: 160px;
    }

    &__link {
        display: flex;
        align-items: flex-end;
        gap: 20px;
        position: relative;

        &:before {
            position: absolute;
            content: "";
            width: 85px;
            height: 85px;
            mask-image: url(/wp-content/themes/cc-pays-de-lalbenque-limogne/assets/src/images/sprite-images/home/Rond-accesrapides.svg);
            top: -10px;
            left: 35px;
            z-index: 0;
            mask-repeat: no-repeat;
            background-color: $color-light;
            transition: all $duration ease;
        }

        svg {
            position: relative;
            z-index: 2;
            transform: translateX(0);
            transition: all $duration ease;
        }

        &:hover, &:focus {
            &:before {
                background-color: $color-second;
            }

            svg {
                transform: translateX(3px);
            }

            .access__label {
                transform: translateX(-3px);
                color: $color-dark;
            }
        }
    }

    &__label {
        width: 130px;
        font-family: $font-family;
        font-weight: $font-weight-semibold;
        font-size: $font-size--text;
        line-height: 19px;
        position: relative;
        z-index: 2;
        transform: translateX(0);
        transition: all $duration ease;
    }
}

//======================================================================================================
// Actualités
//======================================================================================================

.news {
    margin-top: 80px;
    position: relative;

    &:before {
        position: absolute;
        content: "";
        width: 1900px;
        height: 1900px;
        background-image: url(/wp-content/themes/cc-pays-de-lalbenque-limogne/assets/src/images/sprite-images/home/Rond-actu.svg);
        top: -655px;
        left: calc(50% + -515px);
        z-index: -1;
        background-repeat: no-repeat;
        transform: rotate(-25deg);
        background-size: 1070px;
        background-position: center;
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__title {
        &--small {
            max-width: 310px;
            margin: 0 auto;
            text-align: center;
            margin-top: 40px;
            font-family: 'Sofia Sans Bold';
            font-weight: 400;
            font-size: $font-size--3;
            line-height: 30px;
            color: $color-dark;
        }
    }

    &__list {
        display: flex;
        justify-content: space-between;
        gap: 30px;
        margin-top: 50px;
    }

    &__item {
        width: 370px;
    }

    &__image {
        position: relative;
        width: 370px;
        height: 250px;
        overflow: hidden;
        
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        &--notimg {
            background: url('/wp-content/themes/noyau/assets/images/icon-post.png') no-repeat $color-third--rgba;
            background-position: center;
            width: 370px;
            height: 250px;
        }
    }

    &__link {
        &:hover, &:focus {
            .news__taxonomy {
                background-color: $color-third;
                color: $color-dark;
            }

            .news__readmore {
                &:before {
                    width: 100%;
                }
            }
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__taxonomy {
        font-family: $font-family;
        font-weight: $font-weight-semibold;
        font-size: $font-size--text;
        line-height: 20px;
        background-color: $color-second;
        border-radius: 50px;
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        text-transform: lowercase;
        min-width: 150px;
        min-height: 40px;
        width: fit-content;
        padding: 0 30px;
        margin-top: -20px;
        transition: all $duration ease;
        position: relative;

        span {
            width: 10px;
            height: 10px;
            border-radius: 50px;
            background-color: $color-dark;
            display: inline-flex;
        }
    }

    &__readmore {
        text-transform: lowercase;
        margin-top: 20px;
        font-family: $font-family;
        font-weight: $font-weight-semibold;
        font-size: $font-size--text;
        line-height: 20px;
        color: $color-main;
        width: fit-content;
        position: relative;

        &:before {
            position: absolute;
            content: "";
            width: 0;
            height: 2px;
            left: 0;
            bottom: -3px;
            background-color: $color-main;
            transition: all $duration ease;
        }
    }

    &__button {
        margin-top: 50px;
    }
}

//======================================================================================================
// Évènements
//======================================================================================================

.home-events {
    margin-top: 110px;

    &__title {
        &--small {
            margin: 25px auto 10px;
            font-family: 'Sofia Sans';
            font-weight: $font-weight-bold;
            font-size: $font-size--3;
            line-height: 30px;
            color: $color-dark;
            text-align: center;
            transition: all $duration ease;
        }
    }

    &__navigation {
        display: flex;
        align-items: center;
        justify-content: center;

        &__prev,
        &__next {
            top: unset;
            right: unset;
            left: unset;
            position: relative;
            bottom: unset;
            margin-top: unset;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            margin: 0;
            padding: 0;
            background-color: $color-dark;
            border-radius: 30px;
            transition: all $duration ease;

            &::after {
                position: relative;
                content: "";
                top: 0;
                width: 40px;
                height: 40px;
                mask-repeat: no-repeat;
                background-color: $color-white;
                transition: all $duration ease;
            }

            &:hover, &:focus {
                background-color: $color-second;
                
                &:after {
                    background-color: $color-dark;
                }
            }
        }

        &__prev {
            margin-right: 45px;
            &::after {
                mask-image: url(/wp-content/themes/cc-pays-de-lalbenque-limogne/assets/src/images/sprite-images/Pictos/ic-nav-left.svg);
                left: -1px;
            }
        }

        &__next {
            margin-left: 45px;
            &::after {
                mask-image: url(/wp-content/themes/cc-pays-de-lalbenque-limogne/assets/src/images/sprite-images/Pictos/ic-nav-right.svg);
                left: 1px;
            }
        }
    }

    &__background {
        position: relative;

        &:before {
            position: absolute;
            content: "";
            width: 100vw;
            height: 486px;
            top: 125px;
            left: 0;
            background-color: $color-third;
        }

        &:after {
            position: absolute;
            content: "";
            width: 100%;
            height: 486px;
            background-image: url(/wp-content/themes/cc-pays-de-lalbenque-limogne/assets/src/images/sprite-images/home/Motif.png);
            top: 125px;
            left: 0;
            z-index: 0;
            background-repeat: repeat;
        }
    }

    &__swiper {
        margin-top: 50px;
        border-left: 1px solid $color-white;
        height: 611px;
    }

    &__image {
        position: relative;
        width: 270px;
        height: 230px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .notimg {
        background: url('/wp-content/themes/noyau/assets/images/icon-events.png') no-repeat #efefef;
        background-position: center;
        position: relative;
        width: 270px;
        height: 230px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    &__item {
        display: flex;
        justify-content: center;
        position: relative;
        padding-bottom: 135px;

        &:before {
            position: absolute;
            content: "";
            width: 1px;
            height: 550px;
            top: 125px;
            right: -15px;
            background-color: $color-white;
        }
    }

    &__link {
        width: 270px;
        display: inline-flex;

        &:hover, &:focus {
            .home-events__date {
                p {
                    color: $color-dark;
                }

                p:first-child::before {
                    background-color: $color-second;
                }
            }

            .home-events__title--small {
                color: $color-main;
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__date {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 35px;

        p {
            font-family: 'Sofia Sans Bold';
            font-size: $font-size--text-medium;
            line-height: 24px;
            text-transform: uppercase;
            position: relative;

            &:first-child {
                margin-left: 20px;

                &:before {
                    position: absolute;
                    content: "";
                    width: 40px;
                    height: 40px;
                    top: -9px;
                    left: -20px;
                    mask-image: url(/wp-content/themes/cc-pays-de-lalbenque-limogne/assets/src/images/sprite-images/home/Rond-dateagenda.svg);
                    mask-repeat: no-repeat;
                    background-color: $color-white;
                    z-index: -1;
                    transition: all $duration ease;
                }
            }
        }

        > span {
            width: 20px;
            height: 4px;
            background-color: $color-dark;
        }
    }

    &__location {
        display: flex;

        p {
            font-family: 'Sofia Sans';
            font-weight: $font-weight-normal;
            font-style: italic;
            font-size: 20px;
            color: $color-dark;
        }

        svg {
            width: 25px;
            height: 25px;
            min-width: 26px;
            min-height: 26px;
            fill: $color-white;
        }
    }

    &__buttons {
        display: flex;
        justify-content: center;
        transform: translateY(-120px);
        z-index: 5;
        position: relative;
    }
}

//======================================================================================================
// Territoire
//======================================================================================================

.home-map {
    margin-top: 60px;
    position: relative;

    &:before {
        position: absolute;
        content: "";
        width: 1900px;
        height: 800px;
        background-image: url(/wp-content/themes/cc-pays-de-lalbenque-limogne/assets/src/images/sprite-images/home/Rond-territoire.svg);
        top: -115px;
        left: calc(50% + -958px);
        z-index: -1;
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 1270px;
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__content {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &__list {
        display: flex;
        gap: 0 15px;
        margin-top: 90px;
        position: relative;

        &:before {
            position: absolute;
            content: "";
            width: 945px;
            height: 460px;
            right: -375px;
            top: -40px;
            background-color: $color-main;
            border-radius: 0px 0px 0px 50px;
        }

        &--1 {
            gap: 10px 0;
            display: flex;
            flex-direction: column;
        } 
        
        &--2 {
            gap: 10px 0;
            margin-top: 20px;
            display: flex;
            flex-direction: column;
        }

        li {
            display: flex;
            position: relative;

            a {
                position: relative;
                display: flex;
                align-items: center;
                color: $color-white;
                font-family: $font-family;
                font-weight: $font-weight-normal;
                font-size: $font-size--text;
                line-height: 22px;
                width: 210px;
                padding-left: 10px;
                background-color: transparent;
                transition: all $duration ease;

                &:hover,
                &.ville_hover {
                    font-weight: $font-weight-bold;
                    color: $color-dark;
                    background-color: $color-white;
                    
                    &:before {
                        background: $color-dark;
                    }
                }

                &:before {
                    content: '';
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    background: $color-white;
                    margin-right: 5px;
                    vertical-align: middle;
                    transition: $duration;
                    border-radius: 50%;
                }
            }
        } 
    }

    &__svg {
        position: relative;
        width: 550px;
        height: 560px;
        z-index: 10;
        margin-top: -15px;

        svg {
            width: 550px;
            height: 560px;

            .st0 {
                &:hover, 
                &.map_hover {
                    fill: $color-main;
                }
             }
        }

        #shapes-map {
            position: absolute;
            top: -40px;
            right: -70px;
            z-index: -1;
        }
    }

    &__img {
        position: absolute;
        top: 110px;
        right: -135px;
        z-index: -2;
    }

    &__button {
        &:first-child {
            display: none;
            background-color: $color-dark;

            &:hover, &:focus {
                background-color: $color-main;
            }
        }

        &:last-child {
            box-shadow: 0px 0px 1px rgba(27, 35, 54, 0.08), 1px 1px 10px rgba(27, 35, 54, 0.08);
            transform: translateY(-135px);
            min-width: 240px;
            margin-left: -25px;
        }
    }
}

//======================================================================================================
// Kiosque
//======================================================================================================
.kiosque {
    background-color: $color-third;
    position: relative;

    &:after {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background-image: url(/wp-content/themes/cc-pays-de-lalbenque-limogne/assets/src/images/sprite-images/home/Motif.png);
        top: 0;
        left: 0;
        z-index: 0;
        background-repeat: repeat;
    }

    &-image {
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        &--no-image {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color-bg--image;
        
            svg {
            width: 40%;
            }
        }
    }

    &__container {
        border-right: 1px solid $color-white;
        border-left: 1px solid $color-white;
        padding: 70px 0;
        position: relative;

        &:before {
            position: absolute;
            content: "";
            width: 1px;
            height: 100%;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            background-color: $color-white;
        }
    }

    &__title {
        &--second {
            display: none;
        }
    }

    &__ligne {
        position: absolute;
        width: 600px;
        height: 100%;
        border-right: 1px solid $color-white;
        border-left: 1px solid $color-white;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    &__content {
        position: absolute;
    }

    &__navigation {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        &:before {
            position: absolute;
            content: "";
            width: 200px;
            height: 380px;
            left: -60px;
            top: -170px;
            background-color: $color-third;
        }

        &__container {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 80px;
            z-index: 5;
        }

        &__prev,
        &__next {
            top: unset;
            right: unset;
            left: unset;
            position: relative;
            bottom: unset;
            margin-top: unset;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            margin: 0;
            padding: 0;
            background-color: $color-dark;
            border-radius: 30px;
            transition: all $duration ease;

            &::after {
                position: relative;
                content: "";
                top: 0;
                width: 40px;
                height: 40px;
                mask-repeat: no-repeat;
                background-color: $color-white;
                transition: all $duration ease;
            }

            &:hover, &:focus {
                background-color: $color-second;
                
                &:after {
                    background-color: $color-dark;
                }
            }
        }

        &__prev {
            display: none;
            &::after {
                mask-image: url(/wp-content/themes/cc-pays-de-lalbenque-limogne/assets/src/images/sprite-images/Pictos/ic-nav-left.svg);
                left: -1px;
            }
        }

        &__next {
            &::after {
                mask-image: url(/wp-content/themes/cc-pays-de-lalbenque-limogne/assets/src/images/sprite-images/Pictos/ic-nav-left.svg);
                left: -1px;
            }
        }
    }

    &__container {
        display: flex;
        position: relative;
    }

    .swiper-container {
        width: 270px !important;
        height: 380px !important;
        margin-left: -35px;
        margin-right: unset;

        .swiper-wrapper {
            align-items: center;
        }

        &__image {
            margin-left: 459px;
            overflow: visible;
            clip-path: inset( -100vw 0 -100vw -100vw );
            position: relative;

            &:before {
                position: absolute;
                content: "";
                top: 65px;
                right: 5px;
                width: 250px;
                height: 250px;
                background-image: url(/wp-content/themes/cc-pays-de-lalbenque-limogne/assets/src/images/sprite-images/home/Rond-kiosque.svg);
                background-repeat: no-repeat;
            }

            .swiper-slide {
                opacity: 0;
                transition: all $duration ease-in-out;

                &-prev {
                    opacity: 1!important;
                    width: 210px !important;
                    height: 290px !important;
                    margin-right: -70px;
                }

                &-active {
                    opacity: 1!important;
                    width: 270px !important;
                    height: 380px !important;
                }

                .kiosque-image {
                    img {
                        width: 100%;
                    }
                }
            }
        }

        &__content {
            .kiosque-content {
                height: 100%;
                transition: all $duration ease-in-out;

                &__title {
                    margin-top: 40px;
                    font-family: 'Sofia Sans Bold';
                    font-weight: $font-weight-normal;
                    font-size: $font-size--3;
                    color: $color-dark;
                    width: 270px;
                }

                &__infos {
                    margin: 20px 0;
                    p {
                        span {
                            text-transform: uppercase;
                        }
                    }
                }
            }

            .swiper-slide {
                .kiosque-content {
                    opacity: 0;
                }
            }
            .swiper-slide-active {
                .kiosque-content {
                    opacity: 1;
                    position: absolute;
                    width: 370px;
                    z-index: 5;
                }
            }
        }
    }

    &__buttons {
        display: flex;
        align-items: center;
        gap: 20px;

        > a {
            display: flex;
            align-items: center;
            gap: 20px;
            width: 40px;
            height: 40px;

            svg {
                width: 40px;
                height: 40px;
                border-radius: $border-radius--round;
                background-color: $color-dark;
                transition: all .4s ease-in-out;

                path {
                    fill: $color-white;
                    transition: all .4s ease-in-out;
                }
            }

            &:hover, &:focus {
                svg {
                    background-color: $color-second;
                    path {
                        fill: $color-dark;
                    }
                }
            }
        }
    }

    &__btn {
        &__container {
            position: absolute;
            bottom: 0;
            display: inline-block;
            width: 270px;

            > a {
                width: 100%;
            }
        }
    }
}




//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .home-slideshow .slideshow {
        width: 940px;
        height: 435px;

        &:before {
            left: 310px;
        }

        &:after {
            right: 310px;
        }

        &__content {
            width: 310px;
            padding: 10px 0 15px;
        }

        &__title {
            font-size: 20px;
        }
    }
    
    .access {

        &__list {
            margin-left: 95px;
            gap: 45px 0;
        }

        &__label {
            width: 115px;
            font-size: $font-size--text-small;
        }
    }

    .news {
        &__list {
            gap: 20px;
            width: 100%;
        }

        &__image {
            width: 300px;
            height: 205px;
    
            &--notimg {
                width: 300px;
                height: 205px;
            }
        }
    }

    .home-events {
        margin-top: 100px;

        &__title {
            &--small {
                font-size: 25px;
            }
        }

        &__background {
            height: 570px;

            &:before {
                height: 480px;
                top: 75px;
            }

            &:after {
                height: 429px;
            }
        }

        &__item {
            &:before {
                right: -10px;
                height: 480px;
                top: 75px;
            }
        }

        &__link {
            width: 220px!important;
        }

        &__image {
            width: 220px;
            height: 185px;
        }
    
        .notimg {
            width: 220px;
            height: 185px;
        }
    }

    .home-map {
        margin-top: 20px;

        &:before {
            left: calc(50% - 708px);
            background-size: 1000px;
        }

        &__container {
            height: 650px;
        }

        &__svg {
            margin-top: 50px;

            svg {
                width: 450px;
                height: 455px;
            }
        }

        &__img {
            top: 32px;
            right: 10px;
            width: 150px;
            height: 150px;
        }

        &__list {
            gap: 0;

            &:before {
                width: 835px;
            }
        }

        &__buttons {
            position: absolute;
            bottom: 130px;
            left: calc(50% - 175px);
            z-index: 15;
        }

        &__button:last-child {
            transform: unset;
        }
    }

    .kiosque {
        &__ligne {
            width: 480px;
        }

        &__navigation {

            &:before {
                top: -225px;
                left: -95px;
                width: 230px;
                height: 489px;
            }

            &:after {
                position: absolute;
                content: "";
                background-image: url(/wp-content/themes/cc-pays-de-lalbenque-limogne/assets/src/images/sprite-images/home/Motif.png);
                top: -225px;
                left: -95px;
                width: 230px;
                height: 489px;
                z-index: 0;
                background-repeat: repeat;
            }

            &__container {
                left: 30px;

                &:after {
                    position: absolute;
                    content: "";
                    width: 1px;
                    height: 489px;
                    left: -30px;
                    top: -225px;
                    background-color: $color-white;
                }
            }
        }

        .swiper-container {
            margin-left: -75px;
            width: 250px !important;
            height: 350px !important;

            &__image {
                margin-left: 376px;

                &:before {
                    right: 30px;
                }
    
                .swiper-slide-active {
                    width: 250px !important;
                    height: 350px !important;
                }
    
                .swiper-slide-prev {
                    width: 190px !important;
                    height: 230px !important;
                    margin-right: -70px;
                }
            }

            &__content {
                width: 270px !important;
                height: 350px !important;

                .kiosque-content__title {
                    margin: 0;
                    margin-top: 40px 0 20px;
                }
            }
        }

        &__buttons {
            gap: 25px;
        }

    }
}


// 960
@media screen and (max-width: $medium) {

    .home-slideshow {
        margin-top: 40px;

        .slideshow {
            width: 620px;
            height: 285px;
    
            &:before {
                left: 200px;
            }
    
            &:after {
                right: 200px;
            }
    
            &__content {
                width: 200px;
            }
        }
    }

    .access {
        margin-top: 60px;

        &__title {
            transform: unset;
            top: 0;
            left: 0;
            font-size: 40px;
        }

        &__list {
            margin-left: 0;
            gap: 50px 0;
        }

        &__item {
            &:first-child {
                width: 410px;
                display: flex;
                justify-content: flex-end;
            }
        }
    }

    .news {
        margin-top: 90px;

        &__list {
            flex-direction: column;
            align-items: center;
            gap: 40px 0;
        }

        &__item {
            width: 420px;
        }

        &__image {
            width: 420px;
            height: 285px;
    
            &--notimg {
                width: 420px;
                height: 285px;
            }
        }

        &__button {
            margin-top: 40px;
        }
    }

    .home-events {
        margin-top: 80px;

        &__background {
            &:before {
                top: 125px;
            }
        }

        &__item {
            &:before {
                top: 125px;
            }
        }

        &__link {
            width: 300px!important;
        }

        &__image {
            width: 300px;
            height: 250px;
        }
    
        .notimg {
            width: 300px;
            height: 250px;
        }

        &__buttons {
            transform: translateY(-80px);
        }
    }

    .home-map {
        &:before {
            left: calc(50% - 710px);
            background-size: 1150px;
        }

        &__container {
            position: relative;
            height: 280px;
        }

        &__title {
            position: absolute;
            top: -10px;
            right: 50px;
        }

        &__svg {
            width: 200px;
            height: 200px;
            margin-top: 0;

            svg {
                display: none
            }
        }

        &__img {
            position: relative;
            width: 200px;
            height: 200px;
            top: unset;
            right: -50px;
        }

        &__content {
            width: unset;
            position: absolute;
            left: 0;
            top: 0;
            width: 200px;
            height: 200px;
        }

        &__list {
            display: none;
        }

        &__buttons {
            top: 95px;
            right: 0;
            bottom: unset;
            left: unset;
            gap: 20px 0;
            display: flex;
            flex-direction: column;
        }

        &__button {
            width: 300px;

            &:first-child {
                display: flex;
            }

            &:last-child {
                margin-left: 0;
            }
        }
    }

    .kiosque {
        &__ligne {
            display: none;
        }                             

        .swiper-container {
            margin-left: -70px;
            width: 300px !important;
            height: 420px !important;

            &__image {
                margin-left: 110px;

                &:before {
                    width: 250px;
                    height: 250px;
                    top: 90px;
                    right: 5px;
                }

                .swiper-slide-active {
                    width: 104% !important;
                    height: 103% !important;
                }

                .swiper-slide-prev {
                    opacity: 0 !important;
                }
            }

            &__content {
                .kiosque-content {
                    &__title {
                        margin: 105px 0 20px;
                    }
                }
            }
        }

        &__navigation {
            &:before, &:after {
                display: none;
            }

            &__container {
                top: 165px;
                right: 190px;
                transform: unset;
                left: unset;

                &:after {
                    display: none;
                }
            }
        }
    }
}


// 640
@media screen and (max-width: $small) {

    .home-slideshow {
        margin-top: 30px;
        height: 250px;

        .slideshow {
            width: 300px;
            height: 180px;
            overflow: visible;
    
            &:before {
                left: 95px;
                width: 8px;
            }

            &:after {
                right: 95px;
                width: 8px;
            }
    
            &__content {
                width: 100%;
                height: fit-content;
                position: absolute;
                top: 0;
            }

            &__title {
                text-align: center;
                font-size: 22px;
            }

            &__pagination {
                transform: translateX(-50%);
                left: 50%;
            }

            img {
                border-radius: 0 0 30px 0;
            }
        }

        .container--content {
            bottom: -180px;
            height: 110px;
            position: relative;
        }
    }

    .access {
        padding: 60px 0 80px;

        &__container {
            flex-direction: column;
        }

        &__title {
            position: relative;
        }

        &__list {
            margin: 50px 15px 0;
            gap: 50px 30px;
        }

        &__item {
            &:first-child {
                width: unset;
                display: unset;
                justify-content: unset;
            }
        }

        &__link {
            flex-direction: column;
            align-items: center;

            &:before {
                top: 40px;
                left: 15px;
            }

            &:hover, &:focus {
                svg {
                    transform: translateY(15px);
                }

                .access__label {
                    transform: translateX(0);
                }
            }
        }

        &__label {
            width: 115px;
            text-align: center;
        }
    }

    .news {
        margin-top: 80px;

        &__item {
            width: 300px;
        }

        &__image {
            width: 300px;
            height: 205px;
    
            &--notimg {
                width: 300px;
                height: 205px;
            }
        }
    }

    .home-events {
        margin-top: 100px;

        &__navigation {
            position: relative;

            &__next {
                margin-left: 0;
                position: absolute;
                right: 115px;
                top: 90px; 
            }

            &__prev {
                margin-right: 0;
                position: absolute;
                left: 115px;
                top: 90px;
            }
        }

        &__background {
            &:before {
                height: 444px;
            }
        }

        &__swiper {
            margin-top: 100px;
            border-right: 1px solid $color-white;
            height: 569px;
        }

        &__item {
            &:before {
                display: none;
            }
        }

        &__buttons {
            transform: translateY(-100px);
        }
    }

    .home-map {
        margin-top: 80px;

        &:before {
            display: none;
        }

        &__container {
            height: 260px;
        }

        &__content {
            left: unset;
            right: -20px;
            top: -160px;
            z-index: -1;
        }
    }

    .kiosque {
        &__container {
            flex-direction: column;
            align-items: center;

            &:before {
                display: none;
            }
        }

        &__title {
            display: none;

            &--second {
                display: flex;
            }
        }

        &__navigation {
            &__container {
                left: 0;
                right: 0;
            }
        }

        .swiper-container {
            margin-left: 0px;
            width: 295px !important;

            &__image {
                height: 440px !important;
                margin-left: 336px;
                margin-top: 100px;
                transform: translateX(-8px);

                &:before {
                    top: unset;
                    right: 182px;
                    bottom: -40px;
                }

                .swiper-slide-active {
                    width: 105% !important;
                    height: 105% !important;
                }
            }

            &__content {
                height: 255px !important;

                .kiosque-content {
                    width: 300px;

                    &__title {
                        margin: 20px 0 20px;
                        text-align: center;
                        width: 300px;

                    }

                    &__infos {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 300px;

                    }
                }
            }
        }

        &__buttons {
            justify-content: center;
            width: 300px;

        }

        &__btn__container {
            left: 15px;
        }
    }
}
