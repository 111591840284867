.flexible {
    margin-top: 30px;
}


//======================================================================================================
// Bloc Content (textual content) - alert, paragraph, question-answer, quote
//======================================================================================================
.bloc-content {
    display: flex;
    align-items: center;
    padding: 30px;
    margin: 30px 0;

    &__container {
        flex: 1;
    }

    &__title {
        font-family: $font-family--heading;
        font-weight: $font-weight;
        font-size: $font-size--text-medium ;
        text-transform: uppercase;
    }

    &__content {
        margin-top: 5px;
    }

    &--alert {
        color: $color-white;
        background-color: $color-flexible-alert;
        border-radius: $border-radius;

        svg {
            width: 57px;
            height: 54px;
            fill: $color-white;
            margin-right: 30px;
        }
    }

    &--paragraph {
        .nav-main-link {
            br {
                display: none;
            }
        }
    }

    &--paragraph,
    &--question_answer {
        display: inherit;
        padding: 0;

        p {
            &:not(:first-child) {
                margin-top: 20px;
            }
        }

        a {
            display: inline-block;
            text-decoration: underline;
            color: $color-main;

            &[target="_blank"]::after {
                content: "\f504";
                font: normal 16px/1 dashicons;
                position: relative;
                float: right;
                top: 2px;
                margin-left: 2px;
            }

            &:hover,
            &:active,
            &:focus {
                text-decoration: none;
            }
        }

        ul, ol {
            line-height: 22px;
            padding-left: 20px;
        }

        ul {
            li {
                &::before {
                    content: "•";
                    position: relative;
                    left: -22px;
                    display: flex;
                    color: $color-second;
                    font-size: 2.575rem;
                    height: 0;
                    top: 2px;
                }

                ul {
                    li {
                        &::before {
                            color: $color-main;
                        }

                        ul {
                            li {
                                &::before {
                                    color: $color-dark;
                                }
                            }
                        }
                    }
                }
            }
        }

        ol {
            list-style-type: decimal;
        }

        & > ul, & > ol {
            margin: 20px 0;
        }

        &.border {
            padding: 15px;
            border-left: 15px solid;

            > svg {
                display: none;
            }

            &.primary {
                border-color: $color-main;
            }

            &.secondary {
                border-color: $color-second;
            }

            &.neutral {
                border-color: $color-third;
            }
        }

        &.background {
            padding: 30px;
            color: $color-white;
            border-radius: $border-radius;
            display: flex;
            align-items: center;

            > svg {
                margin-right: 20px;
                margin-left: -15px;
                width: 80px;
                height: 80px;
            }

            &.primary {
                background: $color-main;
                color: $color-white;

                > svg {
                    fill: $color-white;
                }

                ul {
                    li {
                        &::before {
                            color: $color-white;
                        }
                    }
                }

                a {
                    color: $color-white;
                }

                p {
                    color: $color-white;
                }
            }

            &.secondary {
                background: $color-second;
                color: $color-dark;

                > svg {
                    fill: $color-dark;
                }

                ul {
                    li {
                        &::before {
                            color: $color-dark;
                        }
                    }
                }

                p {
                    color: $color-dark;
                }

                a {
                    color: $color-dark;
                }
            }

            &.neutral {
                background: $color-third;
                color: $color-text;

                > svg {
                    fill: $color-text;
                }

                ul {
                    li {
                        &::before {
                            color: $color-text;
                        }
                    }
                }

                a {
                    color: $color-text;
                }

                p {
                    color: $color-dark;
                }
            }

            .bloc-content {
                &__text {
                    flex: 1;
                }
            }

        }
    }

    &--quote {
        justify-content: center;
        align-items: flex-start;
        padding: 0;

        .bloc-content__container {
            max-width: 890px;
            padding: 10px 60px;
        }

        .bloc-content__content {
            position: relative;
            margin: 0;

            svg {
                content: "";
                position: absolute;
                width: 40px;
                height: 30px;
                fill: $color-main;

                &:first-child {
                    top: 0;
                    left: -60px;
                }

                &:last-child {
                    right: -60px;
                    bottom: 0;
                }
            }

            p {
                margin-bottom: 10px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        .bloc-content__author {
            font-weight: $font-weight-medium;
            color: $color-main;
            text-align: right;
            margin-top: 10px;
        }

        .bloc-content__source {
            display: flex;
            justify-content: flex-end;
            color: $color-main;
            text-decoration: underline;
        }
    }

    &.--background1 {
        color: $color-white;
        background-color: $color-main;
    }

    &.--background2 {
        color: $color-white;
        background-color: $color-second;
    }

    &.--background3 {
        background-color: $color-third;
    }

    &.--border1 {
        padding: 10px 20px;
        border-left: 10px solid $color-main;
    }

    &.--border2 {
        padding: 10px 20px;
        border-left: 10px solid $color-second;
    }

    &.--border3 {
        padding: 10px 20px;
        border-left: 10px solid $color-third;
    }
}


//======================================================================================================
// Bloc Album
//======================================================================================================
.bloc-album {
    margin: 30px 0;
    position: relative;

    .swiper-container {
        width: calc(100% - (70px * 2));
        height: 640px;

        .swiper-slide {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: $font-size--5;
            text-align: center;

            & > div {
                display: flex;
                justify-content: center;
                width: 100%;
                height: 100%;

                a {
                    display: flex;
                    width: 100%;
                    height: 100%;

                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }

                .legende {
                    display: flex;
                    position: absolute;
                    bottom: 15px;
                    max-width: 70%;
                    padding: 10px 40px;
                    background: $color-main--rgba;
                    font-size: $font-size;
                    color: $color-white; 
                }
            }
        }

        &[data-captions="false"] {
            .swiper-slide {
                & > div {
                    .legende {
                        display: none;
                    }
                }
            }
        }

        &[data-sizes-full="1"],
        &[data-sizes-square="1"] {
            .swiper-slide {
                & > div {
                    a {
                        img {
                            object-fit: contain;
                            object-position: 50% 50%;
                        }
                    }
                }
            }
        }

        .swiper-button-next:after,
        .swiper-button-prev:after {
            color: $color-main;
        }
    }

    &--basic {
        .swiper-container {

            .swiper-slide {
                & > div {
                    position: relative;
                }
            }

            &[data-scroll_direction="vertical"] {
                .swiper-button-next,
                .swiper-button-prev {
                    left: 50%;
                    transform: translateX(-50%) rotate(90deg);
                }

                .swiper-button-next {
                    top: initial;
                    bottom: 5px;
                }

                .swiper-button-prev {
                    top: 25px;
                    transform: rotate(90deg);
                }
            }

        }
    }

    &--multiple_rows {
        .swiper-container {
            .swiper-wrapper {
                display: flex;
                align-items: center;
                width: 100%;
                height: 640px;

                .swiper-slide {
                    height: calc(100% / 2);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: $font-size--5;
                    text-align: center;
                }
            }

            .swiper-lazy-preloader {
                display: none;
            }

            &[data-margins="true"] {
                .swiper-wrapper {
                    .swiper-slide {
                        height: calc((100% - 30px) / 2);
                    }
                }
            }
        }

    }

    &--coverflow {
        .swiper-container {
            height: auto;

            .swiper-wrapper {
                align-items: center;

                .swiper-slide {
                    width: 370px;
                    height: 370px;

                    .swiper-slide-shadow-left,
                    .swiper-slide-shadow-right {
                        background-image: none;
                    }
                }
            }

            .swiper-lazy-preloader {
                display: none;
            }

            &[data-sizes-square="1"] {
                .swiper-wrapper {
                    .swiper-slide {
                        width: auto;
                        max-width: 370px;
                        max-height: 370px;
                    }
                }
            }
        }
    }

    &--flip {
        .swiper-container {
            .swiper-lazy-preloader {
                display: none;
            }
        }
    }

    &--gallery {
        height: 815px;

        .swiper-container {
            &:not(.gallery-thumbs) {
                width: calc(100% - (70px * 2));
                height: 80%;
            }

            &.gallery-thumbs {
                max-width: 100%;
                height: 20%;
                box-sizing: border-box;
                padding: 10px 0;

                .swiper-wrapper {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    transform: none;

                    .swiper-slide {
                        flex: 1;
                        min-width: calc((100%) / 12);
                        height: auto;
                        opacity: .4;
                        background-size: cover;
                        background-position: 50%;
                        max-width: calc((100%) / 12);
                        min-height: auto;
                        max-height: 75px;
                        margin-bottom: 0;
                        margin-right: 0;

                        &:hover {
                            cursor: pointer;
                            opacity: 1;
                        }

                        &.swiper-slide-thumb-active {
                            opacity: 1;
                        }
                    }
                }
            }

            &[data-margins="true"] {
                & ~ .gallery-thumbs {
                    max-width: 100%;
                    height: 20%;
                    box-sizing: border-box;
                    padding: 10px 0;

                    .swiper-wrapper {
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        transform: none;

                        .swiper-slide {
                            flex: 1;
                            min-width: calc((100% - 120px) / 12);
                            height: auto;
                            opacity: .4;
                            background-size: cover;
                            background-position: 50%;
                            max-width: calc((100% - 120px) / 12);
                            min-height: 70px;
                            margin-bottom: 10px;
                            margin-right: 10px;
                        }

                        &.swiper-slide-visible {
                            &:last-child,
                            &:nth-child(12n) {
                                margin-right: 0 !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        width: 35px;
        height: 35px;
        background: $color-main;
        border-radius: $btn-spe-border-radius;
        transition: all $duration;
        text-align: center;
        line-height: 0;

        svg {
            display: none;
        }

        &::after {
            font-size: $font-size--text;
            font-weight: 700;
            color: $color-white;
            position: relative;
        }
    }

    .swiper-button-prev {
        left: 0;

        &::after {
            left: -1px;
        }
    }

    .swiper-button-next {
        right: 0;

        &::after {
            right: -1px;
        }
    }

}

body.glightbox-open {
    height: initial !important;
}

#glightbox-body.glightbox-clean .gprev,
#glightbox-body.glightbox-clean .gnext,
#glightbox-body.glightbox-modern .gprev,
#glightbox-body.glightbox-modern .gnext {
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    background: $color-main;
    border-radius: $btn-spe-border-radius;
    transition: all .25s;
    padding: 0;
    opacity: 1;

    svg {
        width: 16px;
        height: auto;
        stroke: $color-white;
        stroke-width: 40px;
    }
}

#glightbox-body.glightbox-clean .gclose,
#glightbox-body.glightbox-modern .gclose {
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    background: $color-white;
    border-radius: $btn-spe-border-radius;
    transition: all .25s;
    padding: 0;
    opacity: 1;

    svg {
        width: 13px;
        stroke: $color-main;
        stroke-width: 20px;

        path {
            fill: $color-main;
        }
    }
}

.glightbox-clean .gdesc-inner,
.glightbox-modern .gdesc-inner {
    padding: 0;

    .gslide-title {
        font-weight: $font-weight;
        font-family: $font-family;
        margin-bottom: 0;
        line-height: $line-height;
        display: flex;
        font-size: $font-size;
        position: absolute;
        bottom: 15px;
        padding: 10px 40px;
        background: $color-main--rgba;
        color: $color-white;
        max-width: 70%;
        left: 50%;
        transform: translate(-50%);
    }
}


//======================================================================================================
// Bloc Contextual publication
//======================================================================================================
.contextual-publications {
    .button {
        color: $color-gray;
        border-color: $color-gray;
        margin-bottom: 30px;

        &:hover,
        &:active,
        &:focus {
            color: $color-white;
            background: $color-gray;
        }
    }

    .grid-center {
        width: calc(100% + 30px);
    }
}


//======================================================================================================
// Bloc Chronology
//======================================================================================================
.bloc-chronology {
    display: flex;
    justify-content: space-between;
    position: relative;

    &.grid {
        margin-top: 30px;
    }

    &::before {
        content: "";
        display: block;
        position: absolute;
        background: $color-main;
        width: 1px;
        height: calc(100% - 390px);
        top: 200px;
        left: calc(50% - 1px);
    }

    .card {
        &--chronology {
        height: 100%;
        position: relative;

            &:hover {
                .card__image-wrapper {
                    img {
                        transform: none;
                    }
                }
            }

            &::after {
                content: '';
                display: block;
                position: absolute;
                width: 26px;
                height: 26px;
                background: $color-main;
                border-radius: $btn-spe-border-radius;
                top: calc(50% - 13px);
                left: calc(1200px - 364px * 2);
            }

            &:nth-child(even) {
                margin-top: 120px;
                margin-right: 115px;

                &:nth-child(2) {
                    margin-top: 260px;
                }

                &::after {
                    right: calc(1200px - 364px * 2);
                    left: inherit;
                }
            }

            &:nth-child(odd) {
                margin-left: 115px;

                &:not(:first-child) {
                    margin-top: -140px;
                }
            }
        }

        &__image-wrapper {
            img {
                object-position: top;
            }

            iframe {
                max-width: 100%;
                max-height: 100%;
            }

            video {
                margin: 0;
                max-height: calc(100% + 28px);
            }
        }

        &__container {
            height: 380px;
        }

        &__content {
            height: calc(100% - 180px);
            justify-content: center;
        }

        &__title {
            font-size: $font-size--3;
        }

        &__subtitle {
            font-family: $font-family--heading;
            font-size: $font-size--text-medium ;
        }

        &__label-transcription {
            margin-top: 20px;
            text-decoration: underline;
            cursor: pointer;
        }

        &__transcription {
            display: flex;
            flex-direction: column;
            align-items: baseline;
            text-align: left;
            position: fixed;
            width: 970px;
            min-height: 250px;
            max-height: 700px;
            background: $color-white;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            overflow: auto;
            z-index: 9;
            padding: 40px;
            visibility: hidden;
            opacity: 0;
            transition: all $duration;

            &.show-popup {
                visibility: visible;
                opacity: 1;
            }

        }

        &__close-popup {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 5px;
            right: 5px;
            width: 26px;
            height: 26px;
            border-radius: $btn-spe-border-radius;
            background: $color-third;
            cursor: pointer;
            transition: all $duration;

            &:hover {
                background: $color-main;

                &::before {
                    color: $color-white;
                }
            }

            &::before {
                content: '\f335';
                color: $color-gray;
                font: normal 20px/1 'dashicons';
            }
        }

        &__transcription-title {
            margin-bottom: 20px;
            font-weight: $font-weight--heading;
        }

        &__mask-popup {
            position: fixed;
            width: 100vw;
            height: 100vh;
            left: 0;
            top: 0;
            z-index: 2;
            background: rgba(0, 0, 0, 0.5);
            visibility: hidden;
            opacity: 0;
            transition: all $duration;

            &.show-popup {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    &--without_media {
        &::before {
            background: linear-gradient(135deg, $color-main calc(100% - 170px), $color-bg--transparent calc(100% - 170px / 2));
            height: calc(100% - 20px);
            top: 20px;
        }
    }

    &__container {
        display: flex;
        width: 100%;
        height: auto;
        padding: 0 30px;

        &:nth-child(odd) {
            text-align: right;
            position: relative;

            .bloc-chronology__title,
            .bloc-chronology__subtitle,
            .bloc-chronology__description {
                padding-right: calc(50% + 85px);
            }
        }

        &:nth-child(even) {
            .bloc-chronology__title,
            .bloc-chronology__subtitle,
            .bloc-chronology__description {
                padding-left: calc(50% + 85px);
            }
        }
    }

    &__content {
        width: 100%;
        min-height: 170px;
    }

    &__title {
        font-size: $font-size--3;
        margin-bottom: 10px;
        position: relative;
        margin-top: 0;

        &--only_year {
            font-size: $font-size--1;
        }

        &::before {
            content: "";
            display: block;
            position: absolute;
            width: 26px;
            height: 26px;
            background: $color-main;
            border-radius: 50%;
            top: calc(50% - 13px);
            left: calc(50% - 13px);
        }
    }

    &__subtitle {
        font-family: $font-family--heading;
        font-size: $font-size--text-medium;
        margin-bottom: 10px;
    }
}


//======================================================================================================
// Bloc Document
//======================================================================================================
.bloc-document {
    margin-top: 30px;
}


//======================================================================================================
// Bloc Contact
//======================================================================================================
.bloc-contact {
    margin-top: 30px;

    .card {
        &:hover {
            .card__container {
                background-color: $color-third;
            }
        }
    }
}


//======================================================================================================
// Bloc Embed code
//======================================================================================================
.bloc-embed_code {
    &__iframe {
        max-width: 100%;

        iframe {
            max-width: 100%;
        }
    }
}


//======================================================================================================
// Bloc Nombres
//======================================================================================================
.bloc-numbers {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    &__block {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 20px;
    }

    &__figure {
        font-family: $font-family--heading;
        font-weight: $font-weight--heading;
        font-size: $font-size--2;
        color: $color-main;
    }

    &__unit {
        font-family: $font-family--heading;
        font-weight: $font-weight--heading;
        font-size: $font-size--3;
        color: $color-main;
    }

    &__exponent {
        font-family: $font-family--heading;
        font-weight: $font-weight--heading;
        font-size: $font-size--text;
        color: $color-main;
    }

    &__caption {
        font-family: $font-family--heading;
        font-weight: $font-weight;
        color: $color-text;
        font-size: $font-size--5;
        max-width: 250px;
    }
}


//======================================================================================================
// Bloc Portraits
//======================================================================================================
.bloc-portraits {
    &.grid {
        margin-top: 30px;
    }

    .card {
        &:nth-child(1n+5) {
            padding-top: 10px;
        }

        &__container {
            padding: 0;
            overflow: hidden;

            &:hover {
                box-shadow: $shadow;
            }
        }

        &__image-wrapper {
            width: 270px;
            background-color: $color-white;

            &--no-image {
                background: url(/wp-content/themes/noyau/assets/images/silhouette-defaut.png) no-repeat bottom;
            }
            
        }

        &:hover {
            .card__image-wrapper img {
                transform: none;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 30px;
            background: $color-third;
        }

        &__title {
            margin-top: 0;
            font-size: $font-size--3;
            color: $color--card;
            margin-bottom: 5px;
        }

        &__subtitle {
            font-family: $font-family--heading;
            text-transform: uppercase;
            font-size: $font-size--5;
        }

        &__description {
            p:not(.card__subtitle) {
                margin-top: 5px;
            }
        }

        &__see-more,
        &__see-less {
            background: $color-white;
        }

        &__more {
            left: 0;
            background: $color-third;
            padding: 0 30px;
        }

        &__links {
            display: flex;

            a {
                display: flex;
                align-items: baseline;
                text-align: center;
                justify-content: center;

                &[target="_blank"]::after {
                    content: "\f504";
                    font: normal 16px/1 dashicons;
                    position: relative;
                    float: left;
                    bottom: -2px;
                    color: $color--card;
                }
            }
        }

        &__phone,
        &__mail {
            &::before {
                font: normal 20px/1 dashicons;
                position: relative;
                top: 4px;
                margin-right: 6px;
                float: left;
                color: $color-main;
            }
        }

        &__mail {
            margin-left: 25px;

            &::before {
                content: '\f466';
            }
        }

        &__phone {
            &::before {
                content: '\f525';
                transform: rotate(90deg);
                font-size: 1.188rem;
            }
        }

        &__networks {
            display: flex;
            justify-content: flex-start;
            margin-top: 30px;
            align-items: center;

            a {
                display: flex;
                width: 30px;
                height: 30px;
                background: $color-main;
                margin: 0 10px;
                border-radius: $btn-spe-border-radius;
                justify-content: center;
                align-items: center;

                &:hover {
                    background: $color-second;
                    
                    &:before {
                        color: $color-dark;
                    }
                }

                &::before {
                    font: normal 20px/1 dashicons;
                    color: $color-white;
                    position: relative;
                }

                &.facebook {
                    &::before {
                        content: '\f305';
                    }
                }

                &.twitter {
                    &::before {
                        content: '\f301';
                        font-size: $font-size--5;
                        left: 1px;
                    }
                }

                &.instagram {
                    &::before {
                        content: '\f12d';
                        font-size: $font-size--5;
                    }
                }

                &.linkedin {
                    &::before {
                        content: '\f18d';
                        font-size: $font-size--5;
                        left: 1px;
                    }
                }
            }
        }
    }

    &.auto {
        .card {
            &__container {
                display: flex;
                flex-direction: column;
                flex-wrap: inherit;
            }

            &__content {
                align-items: center;
                text-align: center;
                padding: 20px;
                justify-content: flex-start;
            }

            &__title {
                margin-top: 0;
                font-family: $font-family;
                font-size: $font-size--5;
                color: $color--card;
                margin-bottom: 10px;
            }

            &__subtitle {
                font-family: $font-family;
                text-transform: none;
                font-size: $font-size--text;
            }

            &__more {
                padding: 0 25px;
                justify-content: space-evenly;
            }

            &__links {
                flex-direction: column;
                align-items: center;
            }

            &__phone,
            &__mail {
                height: 30px;
            }

            &__mail {
                margin-left: 0;
                width: fit-content;
                width: -moz-fit-content;
            }

            &__networks {
                justify-content: center;
                margin-top: 0;
            }
        }
    }

    &--portrait {
        .card {
            &__image-wrapper {
                height: 310px;
                min-height: inherit;
            }
        }

        &.auto {
            .card {
                &__container {
                    height: 480px;
                }

                &__content {
                    max-height: 170px;
                }

                &__more {
                    height: 480px;
                    top: -310px;
                }
            }
        }

        &.full {
            .card {
                &__content {
                    max-height: 310px;
                }
            }
        }
    }

    &--square {
        .card {
            &__image-wrapper {
                height: 270px;
                min-height: inherit;
                background-color: $color-white;
            }
        }

        &.auto {
            .card {
                &__container {
                    height: 440px;
                    line-height: $line-height--heading;
                }

                &__more {
                    height: 440px;
                    top: -270px;
                }
            }
        }

        &.full {
            .card {
                &__content {
                    max-height: 270px;
                }
            }
        }
    }
}


//======================================================================================================
// Bloc Image
//======================================================================================================
.bloc-image {
    display: flex;
    margin: 30px 0;

    &__container {
        display: inline-flex;
        justify-content: center;
        position: relative;
        max-width: 100%;

        a {
            position: relative;

            &[target="_blank"]::after {
                content: "\f504";
                font: normal 16px/1 dashicons;
                position: absolute;
                float: right;
                bottom: 3px;
                margin-left: 2px;
                color: $color-main;
            }
        }

        img {
            max-width: 100%;
        }
    }

  &__caption {
        position: absolute;
        bottom: 20px;
        padding: 10px 40px;
        background: $color-main--rgba;
        color: $color-white;
        pointer-events: none;
        max-width: 100%;
    }

    &.original {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &.center {
        justify-content: center;
    }

    &.right {
        justify-content: flex-end;
    }
}

.bloc-embed_code__iframe {
    margin-top: 20px;
}


//======================================================================================================
// Bloc Video
//======================================================================================================
.bloc-video {
    &__wrapper {
        margin-top: 30px;
    }
}


//======================================================================================================
// Bloc Map
//======================================================================================================
.bloc-map {
    margin: 30px 0;
}


//======================================================================================================
// Bloc Colonnes
//======================================================================================================
.bloc-columns {
    display: flex;

    &--center {
        align-items: center;
    }    

    &__first,
    &__second {
        flex: 1;
    }

    &__first {
        padding-right: 15px;
    }
    
    &__second {
        padding-left: 15px;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .grid-center {
        width: calc(100% + 20px);
    }

    .bloc-album {
        .swiper-container {
            height: 520px;
        }

        &--multiple_rows {
            .swiper-container {
                .swiper-wrapper {
                    height: 520px;
                }
            }
        }

        &--coverflow {
            .swiper-container {
                height: auto;

                .swiper-wrapper {
                    .swiper-slide {
                        width: 300px;
                        height: 300px;
                    }
                }

                &[data-sizes-square="1"] {
                    .swiper-wrapper {
                        .swiper-slide {
                            width: auto;
                            max-width: 300px;
                            max-height: 300px;
                        }
                    }
                }
            }
        }

        &--gallery {
        height: 650px;

            .swiper-container {
                &.gallery-thumbs {
                    .swiper-wrapper {
                        .swiper-slide {
                            min-width: calc((100%) / 9);
                            max-width: calc((100%) / 9);
                        }
                    }
                }
            }
        }
    }

    .bloc-chronology {
        .card {
            &--chronology {
                &::after {
                    left: calc(960px - 247px * 2);
                }

                &:nth-child(even) {
                    margin-top: 120px;
                    margin-right: 0;
                    flex-basis: 380px;
                    max-width: 380px;

                    &:nth-child(2) {
                        margin-top: 260px;
                    }

                    &::after {
                        right: calc(960px - 247px * 2);
                    }
                }

                &:nth-child(odd) {
                    margin-left: 0;
                    flex-basis: 380px;
                    max-width: 380px;

                    &:not(:first-child) {
                        margin-top: -140px;
                    }
                }
            }

            &__transcription {
                width: 780px;
            }
        }
    }

    .bloc-portraits {
        .card__image-wrapper {
            width: 220px;
        }

        &--portrait {
            .card {
                &__image-wrapper {
                height: 250px;
                min-height: inherit;
                }
            }

            &.auto {
                .card {
                    &__container {
                        height: 420px;
                    }

                    &__content {
                        max-height: 170px;
                    }

                    &__more {
                        height: 420px;
                        top: -250px;
                        padding: 0 5px;
                    }
                }
            }

            &.full {
                .card {
                    max-width: 870px;
                    flex-basis: 870px;

                    &__content {
                        max-height: 310px;
                    }

                    &__image-wrapper {
                        height: 310px;
                        width: 270px;
                    }
                }
            }
        }

        &--square {
            .card {
                &__image-wrapper {
                    height: 220px;
                    min-height: inherit;
                }
            }

            &.auto {
                .card {
                    &__container {
                        height: 420px;
                        min-height: 220px;
                    }

                    &__more {
                        height: 420px;
                        top: -220px;
                        padding: 0 5px;
                    }
                }
            }

            &.full {
                .card {
                    max-width: 770px;
                    flex-basis: 770px;

                    &__content {
                        max-height: 270px;
                    }

                    &__image-wrapper {
                        height: 270px;
                        width: 270px;
                    }
                }
            }
        }
    }

    .bloc-embed_code {
        &__iframe {
            iframe {
                max-height: 600px;
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .bloc-content--paragraph.background,
    .bloc-content--question_answer.background {
        padding: 20px;
    }

    .bloc-numbers {
        &__block {
            flex-basis: 50%;
        }
    }

    .bloc-chronology {
        .card {
            &--chronology {
                margin-left: 200px;

                &:nth-child(odd) {
                    margin-left: 200px;
                }

                &::after {
                    left: -60px;
                    top: calc(50% - 59px);
                }

                &:nth-child(even) {
                    margin-top: 100px;
                    margin-right: 0;
                    flex-basis: 320px;
                    max-width: 320px;

                    &:nth-child(2) {
                        margin-top: 100px;
                    }

                    &::after {
                        right: inherit;
                        left: -60px;
                    }
                }

                &:nth-child(odd) {
                    flex-basis: 320px;
                    max-width: 320px;

                    &:not(:first-child) {
                        margin-top: 100px;
                    }
                }
            }

            &__container {
                height: 390px;
            }

            .card__image-wrapper {
                height: 140px;
            }

            &__transcription {
                width: 620px;
            }
        }

        &::before {
            left: calc(210px - 58px);
            height: calc(100% - 400px);
            top: 150px;
        }

        &--without_media {
            &::before {
                height: calc(100% - 20px);
                left: 42px;
                top: 20px;
            }
        }

        .bloc-chronology__container {
            padding: 0;

            &:nth-child(odd) {
                text-align: left;

                .bloc-chronology__description,
                .bloc-chronology__subtitle,
                .bloc-chronology__title {
                    padding-right: 0;
                    padding-left: 85px;
                }
            }

            &:nth-child(2n) {
                .bloc-chronology__description,
                .bloc-chronology__subtitle,
                .bloc-chronology__title {
                    padding-left: 85px;
                }
            }

            .bloc-chronology__title:before {
                left: 30px;
            }
        }
    }

    .bloc-portraits {
        &.full {
            .card {
                max-width: 100%;
                flex-basis: 100%;
            }
        }

        &--portrait {
            &.auto {
                .card__container {
                    height: 530px;
                }

                .card__image-wrapper {
                    height: 340px;
                    min-height: inherit;
                }

                .card__content {
                    max-height: 190px;
                }

                .card__more {
                    height: 530px;
                    top: -340px;
                    padding: 0 15px;
                }
            }
        }

        &--square {
            &.auto {
                .card__image-wrapper {
                    height: 300px;
                    min-height: inherit;
                }

                .card__container {
                    height: 490px;
                }

                .card__more {
                    height: 490px;
                    top: -300px;
                    padding: 0 15px;
                }
            }
        }

        .card__image-wrapper {
            width: 300px;
        }

        .card__description {
            p:not(.card__subtitle) {
                font-size: $font-size--text-small;
            }
        }

        .card__content {
            padding: 0 30px 0 20px;
        }

        .card__subtitle {
            font-size: $font-size--text-small;
        }

        .card__links {
            display: flex;
            flex-wrap: wrap;
        }

        .card__mail {
            margin-left: 0;
            margin-top: 10px;
        }
    }

    .bloc-album {
        .swiper-container {
            width: 100%;
            height: 390px;
            margin-bottom: 90px;
        }

        .swiper-button-prev, .swiper-button-next {
            top: calc(100% + 40px);
        }

        .swiper-button-prev {
            left: calc(50% - 50px);
        }

        .swiper-button-next {
            right: calc(50% - 50px);
        }

        &--coverflow {
            .swiper-container {
                height: auto;
            }
        }

        &--multiple_slides {
            .swiper-container {
                height: 550px;
            }
        }

        &--multiple_rows {
            .swiper-container {
                .swiper-wrapper {
                    height: 400px;
                }
            }
        }

        &--coverflow {
            .swiper-container {
                .swiper-wrapper {
                    .swiper-slide {
                        width: 230px;
                        height: 230px;
                    }
                }

                &[data-sizes-square="1"] {
                    .swiper-wrapper {
                        .swiper-slide {
                            width: auto;
                            max-width: 230px;
                            max-height: 230px;
                        }
                    }
                }
            }
        }

        &--gallery {
            height: 550px;

            .swiper-container:not(.gallery-thumbs) {
                width: 100%;
                height: 70%;
                margin-bottom: 60px;
            }

            .swiper-button-prev, .swiper-button-next {
                top: calc(70% + 35px);
            }

            .swiper-container {
                &.gallery-thumbs {
                    .swiper-wrapper {
                        .swiper-slide {
                            min-width: calc((100%) / 7);
                            max-width: calc((100%) / 7);
                        }
                    }
                }
            }
        }
    }

    .bloc-columns {
        display: block;
    
        &__first {
            padding-right: 0;
        }
        
        &__second {
            padding-left: 0;
        }
    }
}


//600
@media screen and (max-width: $small) {

    .bloc-content {
        padding: 20px;
        margin: 20px 0;

        &--alert {
            flex-direction: column;
            align-items: flex-start;

            svg {
                margin-right: 0;
                margin-bottom: 20px;
            }
        }

        &--paragraph {
            &.background {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }

    .bloc-content--quote,
    .bloc-content--paragraph,
    .bloc-content--question_answer {
        padding: 0;
    }

    .bloc-numbers {
        &__block {
            flex-basis: 100%;
        }
    }

    .bloc-embed_code {
        &__iframe {
            iframe {
                max-height: 500px;
            }
        }
    }

    .bloc-chronology {
        .card--chronology, .card--chronology:nth-child(odd) {
            margin-left: 0;
        }

        .card--chronology:nth-child(2n):nth-child(2) {
            margin-top: 80px;
        }

        .card--chronology:nth-child(odd):not(:first-child) {
            margin-top: 80px;
        }

        .card--chronology {
            &::after {
                left: calc(50% - 21px);
                top: calc(100% + 20px);
            }

            &:nth-child(2n)::after {
                right: inherit;
                left: calc(50% - 21px);
            }

            &:last-child::after {
                display: none;
            }
        }

        .bloc-chronology__container {
            padding: 0;

            &:nth-child(odd) {
                text-align: left;

                .bloc-chronology__description,
                .bloc-chronology__subtitle,
                .bloc-chronology__title {
                    padding-right: 0;
                    padding-left: 40px;
                }
            }

            &:nth-child(2n) {
                .bloc-chronology__description,
                .bloc-chronology__subtitle,
                .bloc-chronology__title {
                    padding-left: 40px;
                }
            }

            .bloc-chronology__title:before {
                left: 0;
            }
        }

        &--without_media:before {
            height: calc(100% - 20px);
            left: 12px;
            top: 20px;
        }

        .card__transcription {
            width: 300px;
            padding: 20px 30px 20px 20px;
        }
    }

    .bloc-portraits {
        &.full {
            .card__image-wrapper {
                width: 100%;
            }

            .card__content {
                max-height: 300px;
                height: auto;
                padding: 20px 30px 20px 20px;
                min-height: 300px;
            }
        }

        &--portrait {
            &.full {
                .card__image-wrapper {
                    height: 340px;
                }
            }
        }

        &--square {
            &.full {
                .card__image-wrapper {
                    height: 300px;
                }
            }
        }
    }

    .bloc-album {
        .swiper-container {
            height: 200px;
        }

        &--multiple_rows {
            .swiper-container {
                height: 400px;
            }
        }

        &--coverflow {
            .swiper-container {
                height: auto;

                .swiper-wrapper {
                    .swiper-slide {
                        width: 180px;
                        height: 180px;
                    }
                }

                &[data-sizes-square="1"] {
                    .swiper-wrapper {
                        .swiper-slide {
                        width: auto;
                        max-width: 180px;
                        max-height: 180px;
                        }
                    }
                }
            }
        }

        &--gallery {
            height: 600px;

            .swiper-container:not(.gallery-thumbs) {
                width: 100%;
                height: 30%;
                margin-bottom: 60px;
            }

            .swiper-button-prev, .swiper-button-next {
                top: calc(30% + 35px);
            }

            .swiper-container {
                &.gallery-thumbs {
                    height: 60%;

                    .swiper-wrapper {
                        .swiper-slide {
                            min-width: calc((100%) / 3);
                            max-width: calc((100%) / 3);
                        }
                    }
                }
            }
        }
    }
}
