.footer {
    &__infos {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 60px 0;
    }
    
    &__logo {
        margin-left: 90px;
    }

    &__content {
        display: flex;
        gap: 30px;
    }

    &__coordonnees {
        width: 370px;
    }

    &__horaires {
        width: 270px;
        margin-right: 110px;
    }

    &__title {
        font-family: $font-family--heading-bold;
        font-weight: $font-weight-normal;
        font-size: $font-size--4;
        line-height: 24px;
    }

    &__text {
        font-family: $font-family;
        font-weight: $font-weight-normal;
        font-size: $font-size--text;
        line-height: 22px;
        margin: 15px 0;
    }

    &__phone {
        font-family: $font-family;
        font-weight: $font-weight-bold;
        font-size: $font-size--text;
        line-height: 22px;
        width: fit-content;
        position: relative;

        &:before {
            position: absolute;
            content: "";
            width: 0;
            height: 2px;
            left: 0;
            bottom: -3px;
            background-color: $color-main;
            transition: all $duration ease;
        }

        &:hover, &:focus {
            color: $color-main;
            &:before {
                width: 100%;
            }
        }
    }

    &__button {
        background-color: $color-second!important;
        color: $color-dark!important;
        width: 100%!important;
        margin-top: 20px;

        span {
            background-color: $color-dark!important;
        }

        &:hover, &:focus {
            background-color: $color-third!important;

            span {
                background-color: $color-dark!important;

                &:before {
                    background-color: $color-dark!important;
                }
            }
        }
    }

    &__menu {
        padding: 30px 0;
        background: $color-main;

        ul {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
        }

        li {
            position: relative;
            padding: 0 30px;

            a {
                color: $color-white;
                width: fit-content;
                position: relative;

                &:before {
                    position: absolute;
                    content: "";
                    width: 0;
                    height: 2px;
                    left: 0;
                    bottom: -3px;
                    background-color: $color-white;
                    transition: all $duration ease;
                }

                &:hover, &:focus {
                    &:before {
                        width: 100%;
                    }
                }
            } 

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                right: -5px;
                width: 10px;
                height: 10px;
                background: $color-white;
                border-radius: 50%;
                transform: translateY(-50%);
            }

            &:last-child::before {
                background :transparent;
            }
        }
    }
}


// 1200
@media screen and (max-width: $large) {

    .footer {
        &__logo {
            margin-left: 55px;
        }

        &__content {
            gap: 50px;
        }

        &__coordonnees {
            width: 300px;
        }

        &__horaires {
            margin-right: 50px;
        }

        &__menu li {
            padding: 0 25px;
            font-size: 14px;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }
    }

}

// 960
@media screen and (max-width: $medium) {

    .footer {
        &__infos {
            align-items: flex-start;
            position: relative;
        }

        &__logo {
            margin-left: 60px;

            img {
                width: 155px;
                height: 190px;
            }
        }

        &__content {
            flex-direction: column;
            margin-right: -10px;
            gap: 40px;
        }

        &__text {
            margin: 15px 0 0;
        }

        &__button {
            position: absolute!important;
            bottom: 60px;
            left: 0;
            width: 270px !important;
        }

        &__menu {
            ul {
                gap: 20px 0;
                justify-content: space-between;
                align-items: unset;
            }

            li {
                padding: 0 40px;
                font-size: 16px;

                &:nth-child(1) {
                    padding-left: 0;
                }

                &:nth-child(3) {
                    padding-right: 0;

                    &:before {
                        display: none;
                    }
                }

                &:nth-child(4) {
                    padding-left: 0;

                    &:before {
                        right: -40px;
                    }
                }

                &:nth-child(5) {
                    &:before {
                        right: -40px;
                    }
                }

                &:nth-child(6) {
                    padding-right: 0;
                }
            }
        }
    }

}

// 640
@media screen and (max-width: $small) {

    .footer {
        &__infos {
            flex-direction: column;
            align-items: center;
            gap: 40px 0;
            padding: 55px 0 60px;
        }

        &__logo {
            margin-left: 0;
        }

        &__content {
            width: 300px;
        }

        &__title {
            text-align: center;
        }

        &__text {
            text-align: center;
        }

        &__phone {
            width: 300px;
            display: flex;
            justify-content: center;
            margin-top: 15px;
        }

        &__horaires {
            width: 300px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__button {
            position: relative !important;
            bottom: unset;
            margin-top: 40px;
        }

        &__menu {

            li {
                padding: 0;

                &:nth-child(1) {
                    &:before {
                        display: none;
                    }
                }

                &:nth-child(2) {
                    padding-right: 0;

                    &:before {
                        display: none;
                    }
                }

                &:nth-child(3) {
                    padding-left: 0;
                    padding-right: 55px;
                    
                    &:before {
                        display: flex;
                        right: 10px;
                    }
                }

                &:nth-child(4) {
                    padding-right: 0;
                    
                    &:before {
                        display: none;
                    }
                }

                &:nth-child(5) {
                    padding-left: 0;

                    &:before {
                        right: -60px;
                    }
                }
            }
        }
    }

}