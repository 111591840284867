//=================================================================================================
// Buttons in general
//=================================================================================================
#{$btn} {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    margin: 5px 14px 5px 0;
    padding: 10px 30px;
    background: $color-dark;
    border-radius: $btn-border-radius;
    font-family: $font-family;
    font-weight: $font-weight-semibold;
    font-size: $font-size--text;
    line-height: 20px;
    color: $btn-color;
    text-transform: lowercase;
    text-decoration: none;
    text-align: center;
    border: none;
    transition: all $duration ease;
    // max-height: 40px;

    &:hover,
    &:focus,
    &:active,
    &.is-active,
    &.is-checked {
        background: $color-main;
        color: $color-white;

        svg {
            fill: $color-white;
        }
    }

    svg {
        width: 26px;
        height: 26px;
        fill: $color-white;
        transition: all $duration ease;
    }
}


//=================================================================================================
// Buttons specifics
//=================================================================================================
.button {

    //===============================================
    // Button variant 1 (Background and color change)
    //===============================================
    &--variant {
        color: $btn-variant-color;
        background: $btn-variant-bg;
        border-color: $btn-variant-border-color;

        svg {
            fill: $btn-variant-color;
        }

        &:hover,
        &:focus,
        &:active,
        &.is-active,
        &.is-checked {
            color: $btn-variant-color--hover;
            background: $btn-variant-bg--hover;
            border-color: $btn-variant-border-color--hover;

            svg {
                fill: $btn-variant-color--hover;
            }
        }
    }


    //===============================================
    // Button filter
    //===============================================
    &--filter {
        color: $btn-filter-color;
        background: $btn-filter-bg;
        margin: 10px 5px 10px 0;
        max-height: 35px;
        text-transform: uppercase;
        font-family: $font-family;
        font-weight: $font-weight-normal;
        font-size: 13px;
        line-height: 20px;
        color: $color-dark;
        background: $color-third;
        padding: 10px 15px;

        svg {
            fill: $btn-filter-color;
        }

        &:hover,
        &:focus,
        &:active,
        &.is-active,
        &.is-checked {
            color: $color-white;
            background: $color-main;
        }
    }


    //===============================================
    // Button toggler
    //===============================================
    &--toggler,
    &--toggler:active,
    &--toggler:focus {
        color: $color-dark;
        background: transparent;
        border: 2px solid $color-dark;
        max-height: 35px;
        text-transform: uppercase;
        font-family: $font-family;
        font-weight: $font-weight-normal;
        font-size: 13px;
        line-height: 20px;
        padding: 10px 15px;

        svg {
            fill: $color-dark;
        }

        &.is-open {
            svg {
            transform: rotate(180deg);
            }
        }

        &:hover {
            color: $color-white;
            background: $color-dark;

            svg {
                fill: $color-white;
            }
        }
    }


    //===============================================
    // Button tag
    //===============================================
    &--tag,
    &--tag--medium,
    &--tag--low {
        margin: 0 5px 10px 0;
        background-color: $btn-tag-bg;
        color: $btn-tag-color;

        &:hover, &:focus {
            background-color: $color-dark;
        }
    }

    &--tag--medium {
        opacity: 0.8;
    }

    &--tag--low {
        opacity: 0.6;
    }

    //===============================================
    // Button primary
    //===============================================
    &--primary {
        padding: 7px 30px;
    }


    //===============================================
    // Button empty
    //===============================================
    &--empty {
        color: $color-dark;
        background: transparent;
        border: 2px solid $color-dark;
        max-height: 35px;
        text-transform: uppercase;
        font-family: $font-family;
        font-weight: $font-weight-normal;
        font-size: 13px;
        line-height: 20px;
        padding: 10px 15px;

        svg {
            fill: $color-dark;
        }

        &.is-open {
            svg {
            transform: rotate(180deg);
            }
        }

        &:hover,
        &:focus,
        &:active,
        &.is-active,
        &.is-checked {
            color: $color-white;
            background: $color-dark;

            svg {
                fill: $color-white;
            }
        }
    }

    .empty-selection {
        color: $color-gray;
        background: transparent;
        border: 2px solid $color-gray;
        max-height: 35px;
        text-transform: uppercase;
        font-family: $font-family;
        font-weight: $font-weight-normal;
        font-size: 13px;
        line-height: 20px;
        padding: 10px 15px;
    }
}


//===============================================
// Specifics links
//===============================================
.link-document {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 20px;
    cursor: pointer;
    font-family: $font-family;
    font-weight: $font-weight-semibold;
    font-size: $font-size--text;
    line-height: 20px;
    line-height: $line-height;
    text-transform: lowercase;
    color: $color-dark;
    transition: all $duration ease;

    svg {
        width: 40px;
        height: 40px;
        background: $color-dark;
        border-radius: 50%;
        fill: $color-white;
        margin-right: 10px;
    }

    &:hover {
        color: $color-dark;

        svg {
            background: $color-second;
            fill: $color-dark;
        }
    }
}

#validateSystemeFilter, #resetSystemeFilter {
    margin: 5px 3px 5px 0;
}

#load-older-posts {
    margin: 5px 0 0;
}

//===============================================
// Animation Voir plus
//===============================================
.showX {
    opacity: 1;
    transition: all 0.30s ease-out;
}

.hideX {
    opacity: 0;
    transition: all 0.30s ease-in;
    pointer-events: none;
}

.sbl-circ-path {
    height: 48px;
    width: 48px;
    color: rgba(90, 90, 90, 0.2);
    position: relative;
    display: inline-block;
    border: 5px solid;
    border-radius: 50%;
    border-right-color: #5a5a5a;
    animation: rotate 1s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

//===============================================
// Reset button
//===============================================
.reset-button-style {
    display: inherit;
    margin: 0;
    padding: 0;
    height: auto;
    background: inherit;
    border: 0;
    border-radius: 0;
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    text-transform: inherit;
    text-decoration: inherit;
    text-align: inherit;
    transition: none;

    svg {
        width: inherit;
        height: inherit;
        fill: inherit;
        margin: 0;
        transition: none;
    }

    &:hover,
    &:focus,
    &:active,
    &.is-active,
    &.is-checked {
        background: inherit;
        border-color: inherit;
        color: inherit;

        svg {
            fill: inherit;
        }
    }
}