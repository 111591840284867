.bloc-content--404 {
    margin: 70px 0 60px;

    h2 {
        margin: 0;
        border: 0;
        font-size: 10rem;
        text-align: center;
        color: $color-main;

        &:before {
            display: none;
        }
    }

    p {
        font-size: $font-size--5;
        text-align: center;
        margin-top: 70px!important;
    }
}

.search--404 {
    width: 970px;

    .search-container {
        z-index: 15;
    }

    .searchResult {
        filter: drop-shadow(0 0 5px rgba(0,0,0,.1));
        z-index: 14;
    }

    .searchInput {
        border: 1px solid $color-second!important;
        background: $color-white;
    }
}

.grid-center-noGutter.container__buttons {
    margin: 30px 0;
}



//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .bloc-content--404 {
        h2 {
            font-size: 10rem;
        }
    }

}
