.header {
    position: relative;

    &--fixed {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        padding: 0;
        z-index: 20;
        height: 120px;
        background-color: $color-white;
        box-shadow: 0 0 1px rgba(27,35,54,.08),1px 1px 10px rgba(27,35,54,.08);

        .header-top {
            &__title {

                svg:first-child {
                    width: 0%
                }
        
                svg:last-child {
                    visibility: visible;
                    opacity: 1;
                }
            }     

            &__content {
                position: relative;
                z-index: 15;
            }

            #nav-main {
                position: absolute;
                top: 0;
                left: calc(50% - 530px);
                background-color: $color-white;

                .nav-main-item {
                    width: unset;
                    border: none;

                    &:hover, &:focus {
                        .nav-main-link::before {
                            width: 10px;
                            height: 10px;
                            left: -5px;
                        }
                    }

                    &:first-child {
                        .nav-main-link {
                            &:before {
                                display: none;
                            }
                        }
                    }

                    .nav-main-link {
                        font-family: $font-family;
                        font-weight: $font-weight-medium;
                        font-size: $font-size--text;
                        line-height: 21px;
                        color: $color-dark;
                        width: fit-content;
                        padding: 0 30px;
                        height: 120px;
    
                        &:before {
                            background-color: $color-main;
                        }

                        &:hover, &:focus {
                            &:before {
                                width: 10px;
                                height: 10px;
                                left: -5px;
                            }
                        }
                    }

                    .sub-menu {
                        left: -70px;
                        border-top: none;

                        &:before, &:after {
                            position: absolute;
                            content: "";
                            width: 375px;
                            height: 100%;
                            top: 0;
                            background-color: $color-main;
                        }

                        &:before {
                            left: -375px;
                        }

                        &:after {
                            right: -375px;
                        }
                    }
                }
            }
        }

        .tools {
            &__content {
                .profil {
                    &__select {
                        width: 40px;
    
                        span {
                            display: none;
                        }
    
                        svg:last-child {
                            display: none;
                        }

                        &:hover, &:focus {
                            background-color: $color-third;

                            svg {
                                fill: $color-dark;
                            }
                        }
                    }

                    &__list {
                        position: absolute;
                        left: -80px;
                    }
                }
            }
        }
    }
}

.header-top {
    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 120px;
    }

    &__titre {
        font-size: 0;
        line-height: 0;
        margin: 0;
    } 

    &__title {
        display: flex;
        align-items: center;
        margin: 0;

        svg:first-child {
            display: flex;
            width: 100%;
            transition: all $duration ease;
        }

        svg:last-child {
            background-color: $color-second;
            border-radius: 50%;
            visibility: hidden;
            opacity: 0;
            transition: all $duration ease;

            path {
                transition: all $duration ease;
            }

            &:hover, &:focus {
                background-color: $color-dark;

                path {
                    fill: $color-white;
                }
            }
        }
    }

    &__content {
        display: flex;
    }

    &__link {
        display: flex;
    }
}

//======================================================================================================
// Tools
//======================================================================================================
#uci_link{
    display: none;
}

body:not(.home) {
    .tools {
        &__content {
            & > svg {
                fill: $color-bg--neutral;
            }
        }
        
        &::before {
            background: $color-bg--neutral;
        }
    }
}

.tools {
    &__content {
        display: flex;
        height: 40px;
        gap: 10px;

        .profil {
            z-index: 12;
            position: relative;
        
            &__select {
                display: flex;
                justify-content: flex-start;
                position: relative;
                background-color: $color-main;
                width: 200px;
                height: 40px;
                z-index: 2;
                margin: 0;
                padding: 0;
                border: none;
                transition: all $duration ease;
        
                svg:first-child {
                    width: 40px;
                    height: 40px;
                    border-radius: 100%;
                }
        
                span {
                    font-family: $font-family;
                    font-weight: $font-weight-semibold;
                    font-size: $font-size--text;
                    line-height: 20px;
                    color: $color-white;
                    text-transform: lowercase;
                    transition: all $duration ease;
                }
        
                svg:last-child {
                    width: 28px;
                    height: 28px;
                    right: 10px;
                    border-radius: 100%;
                    position: absolute;
                    margin-right: 0;
                    padding: 0;
                    transform: rotate(0deg);
                    transition: all $duration ease;

                    &.rotate {
                        transform: rotate(180deg);
                    }
                }
        
                &:hover, &:focus, &:active {
                    svg {
                        fill: $color-white;
                    }
        
                    span {
                        color: $color-white;
                    }
                }
            }
        
            &__list {
                display: none;
                position: relative;
                background-color: $color-dark;
                width: 200px;
                margin-top: -20px;
                border-radius: 0 0 20px 20px;
                box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);

                &:before {
                    position: absolute;
                    content: "";
                    width: 40px;
                    height: 15px;
                    top: -15px;
                    left: 50%;
                    transform: translateX(-50%);
                    background-color: $color-dark;
                    clip-path: polygon(50% 0,10% 100%,90% 100%);
                    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
                }
        
                li {
                    border-top: 1px solid $color-white;

                    &:first-child {
                        border-top: none;
                         
                        a {
                            padding-top: 33px;
                        }
                    }

                    &:last-child {
                        a {
                            border-radius: 0 0 20px 20px;
                        }
                    }
                }
        
                a {
                    color: $color-white;
                    font-size: $font-size--text-small;
                    padding: 15px 25px;
                    text-decoration: none;
                    display: block;
                    transition: all $duration ease;
                
                    &:hover, &:focus {
                        background-color: $color-white;
                        color: $color-dark;
                    }
                }
        
                &.show-profil {
                    display: block;
                }
            }
        }
    }

    &__search {
        &--disabled {
            pointer-events: none;
            opacity: .2;
        }
    }

    .tool {
        background-color: none;
        padding: 0;
        margin: 0;
        border: none;
        border-radius: 50px;
        transition: all $duration ease;

        svg {
            height: 40px;
            width: 40px;
        }

        &:not(.tools__profile) {
            background-color: $color-second;

            svg {
                fill: $color-dark;
            }

            &:hover, &:focus {
                background-color: $color-dark;
                color: $color-white;
    
                svg {
                    fill: $color-white;
                }
            }
        }
    }

    // Google translate
    &__translate {
        position: relative;

        &__wrapper {
            position: absolute;
            left: 50%;
            top: 58px;
            z-index: 11;
            display: none;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
            gap: 10px;
            width: max-content;
            padding: 15px 20px 30px;
            background-color: $color-white;
            border-radius: $border-radius;
            transform: translateX(-50%);
            filter: drop-shadow(0px 0px 1px rgba(27, 35, 54, 0.08)) drop-shadow(1px 1px 10px rgba(27, 35, 54, 0.08));
        
            &.js-open {
                display: flex;
            }

            &::before {
                content: '';
                position: absolute;
                left: 50%;
                top: -6px;
                display: block;
                width: 30px;
                height: 30px;
                background-color: $color-white;
                border-radius: 5px;
                transform: translateX(-50%) rotate(45deg);
                pointer-events: none;
                z-index: -1;
            }

            a.glink {
                position: relative;
                font-family: $font-family;
                font-size: $font-size--text;
                font-weight: $font-weight;
        
                &:hover, &:focus {
                    text-decoration: underline;
                }
        
                &.gt-current-lang {
                    font-weight: $font-weight-bold;
                    text-decoration: underline;
                }
            }
        }

        &__close {
            position: absolute;
            bottom: -10px;
            left: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateX(-50%);
            height: auto;
            padding: 0;
            margin: 0;
            background-color: $color-main;
            border-radius: $border-radius--round;
            transition: background-color $duration ease-in-out;

            svg {
                width: 26px;
                height: 26px;
                fill: $color-white;
                transition: fill $duration ease-in-out;
            }

            &:hover, &:focus {
                background-color: $color-second;

                svg {
                    fill: $color-text;
                }
            }
        }
    }
}

.tools-view {
    position: absolute;
    top: 165px;
    width: 100%;
    z-index: 1;

    .container--tools-view {
        display: flex;
        justify-content: flex-end;

        // #google_translate_element {
        //     display: none;
        // }
    }
}

// iframe.goog-te-menu-frame.skiptranslate {
//     position: fixed;
//     top: 120px !important;
//     left: calc(100% - ((100% - 1200px) / 2) - 335px) !important;
// }

//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: $color-black--rgba;
    color: $color-white;
    transition: all $duration;

    &__container {
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        text-decoration: underline;

        &:hover, &:focus {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__button {
        width: 240px;
        margin-left: 15px;
        background-color: $btn-privacy-bg;
        border: $color-white 2px solid;
        color: $btn-privacy-color;

        &:hover, &:focus {
            background-color: transparent;
            color: $color-white; 
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .header--fixed {
        .header-top #nav-main {
            left: calc(50% - 420px);

            .nav-main-item .nav-main-link {
                font-size: 15px;
                line-height: 18px;
                padding: 0 20px;
            }
        }
    }

    .tools__content {
        gap: 5px;
    }

    // iframe.goog-te-menu-frame.skiptranslate {
    //     position: absolute;
    //     left: calc(100% - (100% - 960px)/2 - 335px)!important;
    //     top: 230px!important;
    // }

}


// 960
@media screen and (max-width: $medium) {

    .header-top {
       &__container {
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
       }

       &__title {
            svg {
                width: 475px;
                height: 70px;
                min-width: 475px;
                max-width: 475px;
            }
       }

       &__content {
            position: absolute;
            top: 137px;
            z-index: 12;
            right: calc(50% + -310px);
       }
    }

    .tools {
       &__content {
            gap: 15px;

            .profil__select {
                background-color: $color-white;

                span {
                    color: $color-main;
                }

                svg {
                    fill: $color-main;
                }

                &:hover, &:focus, &:active {
                    background-color: $color-white;

                    span {
                        color: $color-main;
                    }

                    svg {
                        fill: $color-main;
                    }
                }
            }
       }

       .tool:not(.tools__profile) {
            svg {
                background-color: $color-dark;
                fill: $color-white;
                border-radius: 50px;
            }

            &:hover, &:focus {
                svg {
                    background-color: $color-second;
                    fill: $color-dark;
                }
            }
       }
    }

    // iframe.goog-te-menu-frame.skiptranslate {
    //     position: absolute;
    //     left: calc(100% - (100% - 640px)/2 - 335px)!important;
    //     top: 200px !important;
    // }
  
}


// 640
@media screen and (max-width: $small) {

    .header-top {
        &__container {
            height: unset;
            flex-direction: column-reverse;
        }

        &__title {
            svg {
                width: 300px;
                height: 40px;
                min-width: 300px;
                max-width: 300px;
            }
       }

       &__identity {
            height: 90px;
            display: flex;
            justify-content: center;
            align-items: center;
       }

       &__content {
            position: relative;
            top: unset;
            right: unset;
            height: 60px;
            background-color: $color-main;
            width: 640px;
            left: -170px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
       }
    }

    .tools {
        &__content {
            .profil {
                &__select {
                    width: 40px;

                    span {
                        display: none;
                    }

                    svg:last-child {
                        display: none;
                    }

                    &:hover, &:focus, &:active {
                        background-color: $color-third;

                        svg {
                            fill: $color-dark;
                        }
                    }
                }

                &__list {
                    position: absolute;
                    left: -20px;
                    margin-top: 20px;
                    border-radius: 20px;

                    &:before {
                        left: 20px;
                        transform: unset;
                    }

                    li {
                        &:first-child {
                            a {
                                border-radius: 18px 18px 0 0;
                                padding-top: 15px;
                            }
                        }
                    }
                }
            }
        }
    }

    // iframe.goog-te-menu-frame.skiptranslate {
    //     position: absolute!important;
    //     left: calc(100% - (100% - 300px/2))!important;
    // }

    .privacy {
        &__container {
            flex-direction: column;
            align-items: baseline;
            
            button {
                margin: 25px 0 10px;
            }
        }
    }

}
