.cover {
    position: relative;
    display: flex;
    flex-direction: column;

    &__header {
        width: 1200px;
        margin-top: 30px!important;
        position: relative;

        &--wrapper {
            width: 100%;
            height: 540px;
            background: $color-bg--image;
            border-radius: 0 0 65px 0;
            position: relative;

            &:before, &:after {
                position: absolute;
                content: "";
                width: 15px;
                height: 100%;
                top: 0;
                background-color: $color-white;
                z-index: 5;
            }
    
            &:before {
                left: 385px;
            }
    
            &:after {
                right: 385px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
                border-radius: 0 0 65px 0;
            }
        }

        &--title {
            position: absolute;
            bottom: 0;
            width: 385px;
            background-color: $color-white;
            font-family: $font-family--heading-bold;
            font-weight: $font-weight-normal;
            font-size: $font-size--1;
            line-height: 55px;
            color: $color-dark;
            margin: 0;
            padding: 20px 0;

            &:before {
                position: absolute;
                content: "";
                width: 220px;
                height: 8px;
                bottom: 0;
                left: 0;
                background-color: $color-dark;
            }
        }
    }

    &__informations {
        background: $color-third;
        margin: 80px 0 40px;
        padding: 50px 0;
        position: relative;

        &:before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            background-image: url(/wp-content/themes/cc-pays-de-lalbenque-limogne/assets/src/images/sprite-images/home/Motif.png);
            top: 0;
            left: 0;
            z-index: 0;
            background-repeat: repeat;
        }
    }

    &__title {
        color: $color-main;
        margin: 0 0 10px;
    }

    &__intro {
        color: $color-text;
        font-family: $font-family;
        font-weight: $font-weight-semibold;
        font-size: $font-size--5;
        line-height: 24px;
        margin: 20px 0 0;
    }

    &__heading {
        margin: 30px 0;
    }

    &__date {
        box-shadow: 0 0 0 rgba(40,40,40,.1);
        background-color: $color-main;
        color: $color-white;
        padding: 5px 10px !important;

        svg {
            fill: $color-white;
        }
    }

    &__recurrence {
        p {
            color: $color-main!important;
        }
    }

    &__category {
        color: $color-main;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .cover {
        &__header {
            &--wrapper {
                height: 435px;
    
                &:before {
                    left: 310px;
                }
    
                &:after {
                    right: 310px;
                }
            }

            &--title {
                width: 310px;
            }
        }
    }

}

// 960
@media screen and (max-width: $medium) {

    .cover {
        &__header {
            &--wrapper {
                height: 285px;
    
                &:before {
                    left: 200px;
                    width: 10px;
                }
    
                &:after {
                    right: 200px;
                    width: 10px;
                }
            }

            &--title {
                width: 200px;
                font-size: 2rem;
                padding: 10px 0 20px;
                line-height: 35px;

                &:before {
                    width: 130px;
                }
            }
        }

        &__informations {
            margin: 40px 0 40px;
        }

        &__intro {
            margin: 20px 0 0;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {
        &__header {
            &--wrapper {
                height: 180px;
    
                &:before {
                    left: 100px;
                    width: 8px;
                }
    
                &:after {
                    right: 100px;
                    width: 8px;
                }

                img {
                    border-radius: 0 0 30px 0;
                }
            }

            &--title {
                position: relative;
                text-align: center;
                width: 100%;
                font-size: 2.325rem;
                line-height: 55px;
                padding: 10px 0;

                &:before {
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }

        &__informations {
            margin: 30px 0 40px;
        }
    }

}
